<template>
  <CRow>
    <CModal
        :title="
        submitType === 'Update'
          ? $lang.industry.crud.edit
          : $lang.industry.crud.create
      "
        size="lg"
        :show.sync="largeModal"
        :closeOnBackdrop="false"
        :centered="true"
    >
      <template #footer-wrapper>
        &nbsp;
      </template>
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
          err_msg
        }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">

        <CRow>
          <CCol md="6">
            <div
                class="form-group"
                :class="{ 'form-group--error': $v.industry.title.$error }"
            >
              <label class="form__label">
                <span>{{ $lang.industry.form.title }}</span>
                <required_span/>
              </label>
              <input
                  :maxlength="maxlength.title"
                  :placeholder="$lang.industry.form.title"
                  class="form-control"
                  v-model="industry.title"
              />
              <small
                  class="error"
                  v-if="$v.industry.title.$error && !$v.industry.title.required"
              >
                {{ $lang.industry.validation.required.title }}
              </small>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <label class="form__label"><span>{{ $lang.industry.form.code }}</span>
<!--                <required_span/>-->
              </label>
              <input :maxlength="maxlength.code" :placeholder="$lang.industry.form.code" class="form-control"
                     v-model="industry.code"/>
              <small class="error" v-if="$v.industry.code.$error && !$v.industry.code.required">{{
                  $lang.industry.validation.required.code
                }}
              </small>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label"
              >{{ $lang.industry.form.industry }}
              </label>
              <v-select
                  :options="industryLists"
                  v-model="industry.industryId"
              >
              </v-select>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label">
                  <span id="formTitle">{{
                      $lang.industry.form.industryType
                    }}</span>
                <required_span/>
              </label>

              <v-select
                  :options="industryTypeList"
                  v-model="industry.industryType"
              >
              </v-select>
              <small
                  class="error"
                  v-if="
                    $v.industry.industryType.$error &&
                    !$v.industry.industryType.required
                  "
              >
                {{ $lang.industry.validation.required.industryType }}
              </small>
            </div>
          </CCol>
        </CRow>

        <div class="form-group" :class="{ 'form-group--error': '' }">
          <label class="form__label"
          >{{ $lang.industry.form.isActive }}
          </label>
          <input
              type="checkbox"
              :placeholder="$lang.industry.form.isActive"
              :value="1"
              @change="changedIsActive"
              v-model="industry.isActive"
          />
        </div>
        <div>
          <CButton
              type="submit"
              color="primary"
              :disabled="submitted"
          >
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </div>

      </CForm>
      <!-- <CModalFooter>
          <CButton color="primary">Do Something</CButton>{' '}
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter> -->


    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.industry.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-industry')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Industries')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol md="4">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.industry.form.industry
                    }}</label>
                  <v-select
                      :options="industryOptions"
                      v-model="industry.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol md="4">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.industry.form.subIndustry
                    }}</label>
                  <v-select
                      :options="subIndustryOptions"
                      v-model="industry.subIndustry"
                  ></v-select>
                </div>
              </CCol>
              <CCol md="4">
                <div class="form-group">
                  <label class="form__label d-none d-md-block">&nbsp;</label>
                  <CButton
                      type="submit"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/industries/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Industries')"
          >
            <template #industry="data">
              <span :title="data.row.industry">{{
                  trimfunction(data.row.industry, 50)
                }}</span>
            </template>
            <template #SubIndustry="data">
              <span :title="data.row.SubIndustry">{{
                  trimfunction(data.row.SubIndustry, 50)
                }}</span>
            </template>
            <template #industryType="data">
              <span :title="data.row.industryType.industryType">{{
                  trimfunction(data.row.industryType.industryType, 50)
                }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-industry')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-industry')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {industry, industrytype} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required, requiredIf} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "../../store/maxlength";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "IndustryTest",
  mixins: [Mixin],
  components: {
    required_span,
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      messageColor: "success",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      industryLists: [],
      industryTypeList: [],
      appStatusList: [],
      activePage: 1,
      module: industry,
      moduleIndustryType: industrytype,
      industry: {
        toggle: "",
        isChecked: "",
        title: "",
        code: "",
        industryId: "",
        industryType: "",
        isActive: 1,
      },
      maxlength: {
        title: Maxlength.industry.title,
        code: Maxlength.industry.code,
      },
      columns: ["industry", "SubIndustry", "industryType","isActive"],
      data: [],
      options: {
        headings: {
          industry: this.$lang.industry.table.industry,
          SubIndustry: this.$lang.industry.table.subIndustry,
          industryType: this.$lang.industry.table.industryType,
          isActive: this.$lang.industry.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["industry", "SubIndustry"],
        sortable: ["industry", "SubIndustry"],
        filterable: ["industry", "SubIndustry"],
      },
    };
  },
  validations: {
    industry: {
      title: {
        required,
      },
      industryType: {
        required,
      },
      code: {
        requiredIf: requiredIf(function () {
          return this.industry.industryId === "";
        }),
        // required,
      },
    },
  },
  created() {
    if (
        this.checkPermission("edit-industry") === true ||
        this.checkPermission("delete-industry") === true
    ) {
      this.columns.push("actions");
    }
  },
  mounted() {
    let self = this;
    self.industry.isChecked = "true";
    // this.loadData();
    axios.get(this.listUrlApi(this.module)).then((response) => {
      response.data.data.map(function (value, key) {
        self.industryLists.push({value: value.id, label: value.title});
        if (value.industryId <= 0) {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get(this.listUrlApi(this.moduleIndustryType)).then((response) => {
      response.data.data.map(function (value, key) {
        self.industryTypeList.push({value: value.id, label: value.title});
      });
    });

    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    change(e) {
      const selectedCode = e.target.value;
      const option = this.options.find((option) => {
        return selectedCode === option.code;
      });
      this.$emit("input", option);
    },
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.industry.isActive = 1;
      } else {
        self.industry.isActive = 0;
      }
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.industry.title = "";
      self.industry.code = "";
      self.industry.industryId = "";
      self.industry.industryType = "";
      self.industry.isActive = 1;
      self.largeModal = true;
      this.loadData();
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      this.loadData();
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data.data;
              if (response.data.industryId !== "") {
                document.getElementById("formTitle").innerHTML = "Sub Industry";
                self.industry.industryId = responseData.industry;
                self.industry.industryId = {
                  value: responseData.industryId,
                  label: responseData.industry,
                };
              } else {
                document.getElementById("formTitle").innerHTML = "Title";
                self.industry.industryId = {
                  value: "",
                  label: "",
                };
              }

              self.industry.title = responseData.title;

              self.industry.industryType = {
                value: responseData.industryType.industryTypeId,
                label: responseData.industryType.industryType,
              };
              self.industry.isActive = responseData.isActive;
              self.industry.code = responseData.code;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.data.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDown = 10;
            self.submitted = false;
          });
        }
      });
    },
    rowClicked(item, index) {
      this.$router.push({path: `currencytype/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      self.industryOptions = [];
      self.industryLists = [];
      axios.get(this.listUrlApi(this.module)).then((response) => {
        response.data.data.map(function (value, key) {
          self.industryLists.push({value: value.id, label: value.title});
          if (value.industryId <= 0) {
            self.industryOptions.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    getSubIndustry() {
      let self = this;
      if (self.industry.industry.value > 0) {
        const postData = {industry_id: self.industry.industry.value};
        axios.post(this.listUrlApi(this.module), postData).then((response) => {
          self.subIndustryOptions = [];
          response.data.data.map(function (value, key) {
            if (value.industryId > 0) {
              self.subIndustryOptions.push({
                value: value.id,
                label: value.SubIndustry,
              });
            }
          });
        });
      }
    },
    applyFilter() {
      let self = this;
      let industryId = "";
      let subIndustryId = "";
      if (self.industry.industry != null) {
        industryId = self.industry.industry.value;
      }
      if (self.industry.subIndustry != null) {
        subIndustryId = self.industry.subIndustry.value;
      }
      this.$refs.myTable.customQueries = {
        id: industryId,
        industry_id: subIndustryId,
        applyFilter: "true",
      };
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.industry.industry = {};
      this.industry.subIndustry = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    onSubmit() {
      let self = this;
      this.$v.industry.$touch();
      if (this.$v.industry.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";

        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            let industryId, industryType;
            if (self.industry.industryId !== "") {
              industryId = self.industry.industryId.value;
            } else {
              industryId = "";
            }
            if (self.industry.industryType !== "") {
              industryType = self.industry.industryType.value;
            } else {
              industryType = "";
            }
            const postData = {
              title: self.industry.title,
              code: self.industry.code,
              industryId: industryId,
              industryType: industryType,
              isActive: self.industry.isActive,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.messageColor = "success";
                    self.submitted = false; //Enable Button
                  } else {
                    self.alertMessage = response.data.message;
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }

                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            let industryId, industryType;
            if (self.industry.industryId !== "") {
              industryId = self.industry.industryId.value;
            } else {
              industryId = "";
            }
            if (self.industry.industryType != null) {
              industryType = self.industry.industryType.value;
            } else {
              industryType = "";
            }
            const postData = {
              title: self.industry.title,
              code: self.industry.code,
              industryId: industryId,
              industryType: industryType,
              isActive: self.industry.isActive,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.submitted = false; //Enable Button

                    self.$refs.myTable.refresh();
                    self.messageColor = "success";

                  } else {
                    self.alertMessage = response.data.message;
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                    self.submitted = false; //Enable Button
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
